// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquicker-case-study-js": () => import("./../../../src/pages/inquicker-case-study.js" /* webpackChunkName: "component---src-pages-inquicker-case-study-js" */),
  "component---src-pages-invision-case-study-js": () => import("./../../../src/pages/invision-case-study.js" /* webpackChunkName: "component---src-pages-invision-case-study-js" */),
  "component---src-pages-sightbox-case-study-js": () => import("./../../../src/pages/sightbox-case-study.js" /* webpackChunkName: "component---src-pages-sightbox-case-study-js" */),
  "component---src-pages-sprinklr-case-study-js": () => import("./../../../src/pages/sprinklr-case-study.js" /* webpackChunkName: "component---src-pages-sprinklr-case-study-js" */)
}

